html, body {
  width: 100%;
  background-color: beige;

  overflow-x: hidden;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

/* MUI ROOT */

/* .MuiTabs-root {
  text-transform: none;
} */

/* media elements */
@media screen and (min-width: 800px){
  html {
      text-align: center;
      overflow: auto;
  }
  ul {
    list-style-type: none;
  }
  .home-component {
    display: flex;
    flex-direction: row;
    padding: 2em;
  }
  #about-us {
    text-align: left;
    max-width: 40%;
  }
  #product-card-container {
    width: 600px;
  }
  .product-grid {
    max-width: 80%;
  }
  #main-product-image {
    height: 450px;
  }
  #product-modal-picture {
    height: 600px;
  }
}

/* Reusable elements */
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#logo {
  padding: 2.5em 0 0;
}
#top-banner {
  text-align: center;
  min-width: 100%;
  margin: 0 0 1.5em 0;
}
#company-name {
  font-size: 1.5em;
  font-weight: 800;
}
#slogan {
  font-style: italic;
  padding: 0.15em 0 0;
}

/* Home */
.home-component {
  margin-bottom: 5em;
}
#main-image {
  border: 0;
  max-height: 300px;
  max-width: 100%;
  overflow: hidden;
}
#main-image > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
#about-us {
  padding: 1em;
}
#about-us > h1 {
  text-align: center;
}
#about-us > p {
  padding: 0.5em;
}

/* Financing */
.financing-component {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  animation: fadein 1s;
  padding: 1em;
  margin-bottom: 5em;
  font-size: 20px;
}
.financing-component > h2 {
  text-align: center;
  margin: 0 0 0.5em;
}
#financing-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.1em;
}
#finance-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.25em;
  text-align: center;
}
#finance-app-list {
  list-style-type: none;
  padding: 1em;
}

ul {
  font-size: 20px;
}

/* Ordering */
.ordering-component {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  animation: fadein 1s;
  padding: 1.5em;
  font-size: 24px;
  text-align: center;
}

/* Product */
.product-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5em;
}
.product-intro {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  animation: fadein 1s;
  text-align: left;
}
#product-intro-list {
  list-style-type: none;
  text-align: center;
  font-weight: 500;
}
#product-card-container {
  width: 900px;
}
#prod-list-item {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

/* Footer */
#information-table {
  font-size: 0.9em;
  margin: 1em 1em 0.5em;
  padding: 0 1em;
  font-weight: 500;
  text-align: left;
}
#table-cell-label {
  padding: 0 4px 0 0;
  text-align: left;
}
#misc {
  margin: 0.25em;
  text-align: center;
}

/* Animations */

@keyframes fadein {
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0px);}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0px);}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0px);}
}